import React from "react";
import { Container } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";

const HomeVideoContainer = ({ setShowVideo }) => {
  const videoLink =
    "https://d15oh63c5mva7k.cloudfront.net/DTS_12112024_v045.webm";

  return (
    <Container className="position-relative h-100" fluid>
      <div
        className="cursor-pointer position-absolute m-4 p-3"
        style={{ right: 0 }}
        onClick={() => setShowVideo(false)}
      >
        <AiOutlineClose className="cursor-pointer" size={30} color="white" />
      </div>
      <div className="d-flex justify-content-center align-items-center h-100">
        <video
          autoPlay="autoplay"
          loop="loop"
          playsInline=""
          // poster={Section1Image}
          className="landingPageVideo"
          style={{
            objectFit: "cover",
            width: "75vw",
            zIndex: 0,
          }}
          controls
        >
          <source src={videoLink} type="video/mp4" />
        </video>
      </div>
    </Container>
  );
};

export default HomeVideoContainer;
